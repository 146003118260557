// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../stylesheets/application.scss';
import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';

import OtpInputController from '../controllers/otp_controller';

const application = Application.start();

application.debug = true;
application.register('otp', OtpInputController);

// zendesk widget hack
window.addEventListener('turbo:before-render', () => {
  window.zEACLoaded = undefined;
});
