import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['digit', 'input', 'form', 'submitButton', 'control'];

  connect() {
    this.digitTargets[0]?.focus();

    this.digitTargets.forEach((input) => {
      input.addEventListener('focus', (event) => {
        event.target.select();
      });

      input.addEventListener('keydown', (event) => {
        const { target } = event;
        const targetIndex = this.digitTargets.indexOf(target);
        const isNumeric = /^[0-9]{1}$/.test(event.key);
        const isPaste = (event.ctrlKey || event.metaKey) && event.key === 'v';
        const isDelete = event.key === 'Backspace' || event.key === 'Delete';
        const isTab = event.key === 'Tab';

        if (!isNumeric && !isPaste && !isDelete && !isTab) {
          event.preventDefault();
        }

        if (isTab) {
          this.controlTarget.classList.add('otp--show-focus');
        }

        // if the input is already empty and the backspace key is pressed,
        // move focus to the prior input to make corrections easier
        if (isDelete && event.target.value === '' && targetIndex > 0) {
          event.preventDefault();
          target.value = '';
          this.digitTargets[targetIndex - 1].focus();
        }
      });

      input.addEventListener('keyup', (event) => {
        const { target } = event;
        const targetIndex = this.digitTargets.indexOf(target);
        const digitKey = /^[0-9]$/.test(event.key);
        const moveNext = digitKey || event.key === 'ArrowRight';
        const movePrev = event.key === 'ArrowLeft';

        // if the user types fast, there may have been multiple
        // keydown events before we've advanced the focus to the next
        // input, so if we have a keyup, but the current input value
        // is still blank, set the value here...
        if (digitKey && target.value !== event.key) {
          target.value = event.key;
        }

        if (moveNext && targetIndex < this.digitTargets.length - 1) {
          this.digitTargets[targetIndex + 1].focus();
        } else if (movePrev && targetIndex > 0) {
          this.digitTargets[targetIndex - 1].focus();
        }

        if (digitKey && targetIndex === this.digitTargets.length - 1) {
          this.updateValue();
          target.blur();
        }
      });
    });
  }

  handlePaste(event) {
    event.preventDefault();

    const pastedText = event.clipboardData.getData('text');
    const match = pastedText.match(/(?<digits>[0-9]{1,6})/);

    if (match === null) {
      return;
    }

    const digits = match.groups.digits.split('');

    for (let i = 0; i < this.digitTargets.length; i += 1) {
      this.digitTargets[i].value = i < digits.length ? digits[i] : '';
    }

    this.digitTargets[
      Math.min(digits.length, this.digitTargets.length - 1)
    ].focus();
    this.updateValue();
  }

  updateValue() {
    const inputValue = this.digitTargets.map((target) => target.value).join('');

    if (inputValue.match(/^[0-9]{6}$/)) {
      this.inputTarget.value = inputValue;
      this.submitButtonTarget.disabled = false;
      this.formTarget.submit();
    }
  }
}
